.filter-dashboard {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 9;
    width: 100%;
    background-color: $reader-default-color-invert;
    &.opened {
        position: relative;
        .filter-tab {
            @include media-breakpoint-down('md') {
                .MuiListItemText-primary {
                    font-size: 14px;
                }
                li {
                    height: 40px !important;
                }
            }

        }
    }
    .filter-sort-options {
        height: auto;
        .tabs {
            height: 100%;
            .tab-container {
                height: 224px !important;
                @include media-breakpoint-down('md') {
                    height: 220px !important;
                }
            }
            @include media-breakpoint-down('xs') {
                height: 220px !important;
            }
        }
    }
    .more-options {
      .tabs {
        .tab-container {
          height: 266px !important;
        }
      }
    }
    &.tasks-filter {
        .filter-sort-options {
            .tabs {
                .tab-container {
                    height: 196px !important;
                    @include media-breakpoint-down('md') {
                        height: 180px !important;
                    }
                }
            }
        }
    }
    .filter-button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        button {
            background-color: $blue-color-all-themes;
        }
    }
    .filter-tab {
        max-height: 208px;
        &.openedTabs {
            .closed {
                display: none;
            }
            .opened {
                .MuiCollapse-entered {
                    max-height: 190px;
                    overflow-y: auto;
                }
            }
        }
        .filter-divider {
            margin-left: $container-padding;
        }
        .search-for-document-input {
            padding: 10px 15px;
        }
        .list-item-flex {
            display: flex;
            align-items: center;
            p {
                padding-left: 10px;
                color: $blue-color-all-themes;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

        }
        .sort-option {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            > div {
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                text-align: right;
                height: 100%;
                svg {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                }
            }
        }
        .MuiListItemText-inset {
            padding-left: 10px;
        }
        .MuiCheckbox-root {
            padding: 0px;
        }
    }
    .filter-footer {
        display: flex;
        justify-content: space-around;
        padding: 15px 0px;
        height: 66px;
        width: 100%;
        background-color: $reader-default-color-invert;
        button {
            background-color: $blue-color-all-themes;
            color: $white-color-all-themes;
        }
        @include media-breakpoint-down('md') {
            padding: 8px 0px;
            height: 50px;
        }
    }

    .filter-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em 1em;
        .action-button {
            .delete-button {
                width: 2em;
                height: 2em;
                &:hover {
                    background-color: $icon-hover-bg-color;
                    color: $reader-default-color;
                }
            }
        }
    }
}

.filter-chips {
    padding: 0px 10px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    .filter_chip {
        width: auto;
        max-width: 100%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 4px;
        margin-top: 4px;
        justify-content: space-between;
        background-color: $chip-background-color;
        font-size: 14px;
        margin-right: 5px;
        margin-left: 5px;
    }
}
