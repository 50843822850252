.item__link {
  .yonder-list-item-content .list-item-tertiary {
    color: $warning-color;
  }
}

.yonder-list-item {
  .secondary-action-hover {
    display: none !important;
  }
  &:hover {
    .secondary-action-hover {
      display: block !important;
    }
  }
  &.Mui-selected {
    position: relative;
    &:before {
      content: "";
      width: 0.3em;
      height: 100%;
      background-color: $blue-color-all-themes;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
  &.item__doc-notification {
    .list-item-meta {
      color: $warning-color;
    }
    .is-effective {
      color: #EA3829;
    }
  }
}
.yonder-list .MuiDivider-root {
  &.favorites-divider {
    width: 100%;
    margin-left: 0;
  }
}

.MuiDivider-root.folder-divider {
  width: 100%;
  margin-left: 16px;
}

.yonder-list-item.change-request__list-item {
  &.Mui-selected {
    position: relative;
    &:before {
      display: none;
    }
  }
  .yonder-list-item-icon {
    svg {
      width: 35px;
      height: 100%;
    }
    min-width: 0;
    margin-right: 5px;
  }
  .yonder-list-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    .icon-and-metadata {
      display: flex;
      .metadata {
        overflow-x: hidden;
        .list-item-meta {
          justify-content: flex-start;
          white-space: nowrap;
          display: block;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
