.disabled-module-link {
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.module-view .paper .module-view-content .document-content a,
.reader > .MuiGrid-container .reader-content .module .content .document-content a {
  &.archived-module-link {
    color: inherit;
    text-decoration: inherit;
  }
}

.split-view-item .document-content {
  &.faded-out {
    opacity: 0.5;
  }
}


.module-version-header {
  background-color: $background-color;
  padding-left: 20px;
  border-bottom: $default-border;
  height: $module-version-header-height;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.archived-version{
  color: $invalid-color !important;
}

.non-existing-version {
  color: $error-color !important;
}

.module-version-title {
  @include module-title;

  .text {
    display: inline-flex;

    * :not(span)  {
      margin-right: $base-padding;
    }
  }
  .right-to-left {
    display: flex;
    &__text {
      margin-left: auto;
    }
  }
}

.module-version {
  &.module-version-item {
    padding: 2 * $container-padding;
    overflow-y: auto;
    height: calc(100% - #{$container-padding * 2});
    &.legal-cr {
      .module-version-content.content {
        .fr-view {
          padding-top: 2em;
        }
      }
    }
    &.faded-out {
      opacity: 0.5;
    }
  }

  .auto-cr-legal {
    position: relative;
    top: 2em;
    width: 90%;
    left: 1.5em;
    display: flex;
    flex-direction: column;
    .warning-message {
      display: flex;
      align-items: center;
      svg {
        fill: #ffd400;
      }
    }
    .comparison-view-link {
      color: $blue-color-all-themes;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .module-version-content {
    padding: 2 * $container-padding;
    padding-top: 3 * $container-padding;
    overflow: auto;
    height: 100%;
  }
}

.module-view {
  .module-view-content {
    .module-version-content {
      overflow-y: auto;
      padding: 2 * $container-padding;
    }
  }
}

.module-version-subscreen {
  .subscreen-content {
    .split-view {
      .module-version {
        .module-version-content {
          * {
            margin-left: 0;
            margin-right: 0;
          }

          tablewrapper {
            overflow: auto;
          }
        }
      }
    }
  }
}

.module {
  &.focused {
    background-color: rgb(225, 225, 225) !important;

    .module-version-content {
      background-color: rgb(225, 225, 225) !important;

      *:not(.highlight) {
        background-color: rgb(225, 225, 225) !important;
      }
    }
  }
}

.dynamic-content[data-dc-type='index'] {
  // Hide the generated part of dynamic content in Editing view (it's still visible within the code editor)
  .dynamic-content-generated {
    display: none;
  }
}
.dynamic-content-labels {
  display: none;
}

.dynamic-content-revision-bulletin {
  td {
    border: none;
  }

  .summary {
    font-weight: bold;
  }
}
