.spinner {
  margin: $spinner-margin;

  &-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    &.absolute {
      position: absolute;
    }

    &.fixed {
      position: fixed;
    }

    &.absolute,
    &.fixed {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &.app {
    margin: 0;
    min-height: 100vh;
    min-width: 100vh;
  }
}

.small-spinner-container {
  &.space-around {
    min-height: 80px;
  }
  flex-grow: 0 !important;
  .spinner {
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin: 0 !important;
  }
}

.medium-spinner-container {
  .spinner {
    width: 1.7rem !important;
    height: 1.7rem !important;
    margin: 10px !important;
  }
}
